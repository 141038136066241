import React, { Dispatch, SetStateAction, createContext, useState } from "react";
import { Optional } from "sonobello.utilities.react.mui";

import { MsalSessionToken, Token } from "../models/Token";

interface AppContextProps {
  /** See {@link Token}. */
  token?: Token;
  /** Setter to update the application auth token. */
  setToken: Dispatch<SetStateAction<Optional<Token>>>;
}

/** Get the first value from the session store which has a key matching the supplied regex. */
const queryFromSession = <T extends Record<string, unknown>>(key: string, value: unknown): T | undefined => {
  const count = sessionStorage.length;
  for (let x = 0; x < count; x++) {
    const storeKey = sessionStorage.key(x);
    if (!storeKey) continue;
    const storeValue = JSON.parse(sessionStorage.getItem(storeKey) as string);
    try {
      if (storeValue[key] === value) return storeValue as T;
    } catch {
      return undefined;
    }
  }
  return undefined;
};

const AppContext = createContext({} as AppContextProps);

export const AppContextProvider = ({ children }: { children: React.ReactNode | React.ReactNode[] }): JSX.Element => {
  const [token, setToken] = useState(() => {
    const sessionToken = queryFromSession<MsalSessionToken>("credentialType", "AccessToken");
    return sessionToken?.expiresOn && sessionToken?.secret ? new Token(sessionToken) : undefined;
  });
  return <AppContext.Provider value={{ token, setToken }}>{children}</AppContext.Provider>;
};

export default AppContext;
