import { ComparatorFn } from "sonobello.utilities.react.mui";

/** A model representing a Textel disposition with a its external id. */
export class TextelDisposition {
  /** The PK for the disposition configuration. */
  id!: number;
  /** The plain text name for the disposition. */
  name!: string;
  /** The configured external code for the disposition. */
  textelCode!: number;

  /** Comparator which orders Textel Dispositions by name. */
  static nameComparator: ComparatorFn<TextelDisposition> = (
    disposition1: TextelDisposition,
    disposition2: TextelDisposition
  ) => (disposition1.name > disposition2.name ? 1 : -1);

  /** Comparator which orders Textel Dispositions by code. */
  static codeComparator: ComparatorFn<TextelDisposition> = (
    disposition1: TextelDisposition,
    disposition2: TextelDisposition
  ) => (disposition1.textelCode > disposition2.textelCode ? 1 : -1);
}
