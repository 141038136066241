import { AccountInfo, InteractionRequiredAuthError, PublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { Box, CssBaseline, ThemeProvider, Toolbar } from "@mui/material";
import { DateTime } from "luxon";
import { SnackbarProvider } from "notistack";
import React, { useContext, useEffect, useRef } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Theme from "./assets/Theme";
import AppContext, { AppContextProvider } from "./components/AppContext";
import Login from "./components/Login/Login";
import Main from "./components/Main";
import Navigation from "./components/Navigation";

interface Props {
  msalInstance: PublicClientApplication;
}

const App: React.FC<Props> = ({ msalInstance }: Props) => (
  <MsalProvider instance={msalInstance}>
    <ThemeProvider theme={Theme}>
      <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "right" }} maxSnack={3}>
        <CssBaseline />
        <UnauthenticatedTemplate>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="*" element={<Navigate to="login" />} />
          </Routes>
        </UnauthenticatedTemplate>
        <AuthenticatedTemplate>
          <AppContextProvider>
            <AppContent />
          </AppContextProvider>
        </AuthenticatedTemplate>
      </SnackbarProvider>
    </ThemeProvider>
  </MsalProvider>
);

export default App;

const AppContent: React.FC = () => {
  const { accounts, instance: msalClient } = useMsal();
  const { token, setToken } = useContext(AppContext);
  const tokenRef = useRef(token);

  // watch the state of the msal access token
  useEffect(() => {
    // if token is cleared, renew it
    if (tokenRef.current && !token) {
      const silentRequest: SilentRequest = {
        account: accounts[0] as AccountInfo,
        scopes: [`${process.env.REACT_APP_MSAL_AUTHENTICATION_SCOPE}`],
        forceRefresh: true,
        tokenQueryParameters: { resource: `${process.env.REACT_APP_MSAL_APP_ID}` }
      };
      msalClient
        .acquireTokenSilent(silentRequest)
        .then(token => {
          if (!token.accessToken || !token.expiresOn) {
            // If a valid token was not received, fallback to interaction.
            return msalClient.acquireTokenRedirect(silentRequest);
          } else setToken({ secret: token.accessToken, expiresOn: DateTime.fromJSDate(token.expiresOn) });
        })
        .catch(error => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return msalClient.acquireTokenRedirect(silentRequest);
          }
          console.log(error);
        });
    }
    tokenRef.current = token;
  }, [token, accounts, msalClient]);

  return (
    <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
      <Navigation />
      <Toolbar />
      <Box sx={{ height: "100%", width: "100%" }}>
        <Main />
      </Box>
    </Box>
  );
};
