import { DateTime } from "luxon";

/** An interface of the access token that MSAL creates in session storage. */
export interface MsalSessionToken extends Record<string, unknown> {
  /** The raw bearer token. */
  secret: string;
  /** An expression of the expiration time in seconds. */
  expiresOn: string;
}

/** The authentication information necessary to transact with the Textel API. */
export class Token {
  /** The raw bearer token. */
  secret: string;
  /** The date time representing the expiration of the secret. */
  expiresOn: DateTime;

  constructor({ secret, expiresOn }: MsalSessionToken) {
    this.secret = secret;
    this.expiresOn = DateTime.fromSeconds(parseInt(expiresOn));
  }
}
