import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Grid,
  InputAdornment,
  ListItemIcon,
  TextField
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import {
  AdminFormLabel,
  SelectList,
  SelectListItem,
  SelectListItemProps,
  ValidatedTextField
} from "sonobello.utilities.react.mui";

import { CrmCampaignType } from "../../models/CrmCampaignType";
import { TextelCampaign } from "../../models/TextelCampaign";
import useTextel from "../../utils/UseTextel";
import { AdminTool } from "../Main";
import ToolWrapper from "../ToolWrapper";

/** A convenience extension of `CrmCampaignType` so that the plain text name can be directly linked to it. */
interface EnhancedCrmCampaignType extends CrmCampaignType {
  TextelCampaignName?: string;
}

const toolReadme = `
  The Campaign Assignment tool allows you to review and modify the configuration for Zenoti Campaign Types.
  
  The user may assign them to a specified Textel Campaign, and individually configure the number of hours to delay sending messages.
`;
const crmCampaignTypesReadme = `Select a Zenoti Campaign Type to configure.`;
const configurationLabelReadme = `Modify the assigned Textel Campaign or the number of hours to delay sending messages.`;

const CampaignAssignment: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { enqueueSnackbar } = useSnackbar();
  const [crmCampaignTypes, setCrmCampaignTypes] = useState<EnhancedCrmCampaignType[]>();
  const [textelCampaigns, setTextelCampaigns] = useState<TextelCampaign[]>([]);
  const [selectedCampaignType, setSelectedCampaignType] = useState<EnhancedCrmCampaignType>();
  const crmCampaignTypesRef = useRef(crmCampaignTypes);

  // Get CrmCampaignTypes
  const { res: getCampaignTypesResponse, loading: loadingCampaignTypesRequest } = useTextel<CrmCampaignType[]>(
    "Get Crm Campaign Types",
    { url: "ZenotiCampaignTypes" }
  );
  // Get Textel Campaigns
  const { res: getTextelCampaignsResponse, loading: loadingTextelCampaignsRequest } = useTextel<CrmCampaignType[]>(
    "Get Textel Campaigns",
    { url: "TextelCampaigns" }
  );

  useEffect(() => {
    if (!getCampaignTypesResponse || !getTextelCampaignsResponse) return;
    setCrmCampaignTypes(
      getCampaignTypesResponse.payload.map(ct => ({
        ...ct,
        TextelCampaignName: getTextelCampaignsResponse.payload.find(c => c.id === ct.textelCampaignId)?.name
      }))
    );
    setTextelCampaigns(getTextelCampaignsResponse.payload);
    crmCampaignTypesRef.current = getTextelCampaignsResponse.payload;
  }, [getCampaignTypesResponse, getTextelCampaignsResponse]);

  // show spinner while loading
  useEffect(
    () => setIsContentLoading(loadingCampaignTypesRequest || loadingTextelCampaignsRequest),
    [loadingCampaignTypesRequest, loadingTextelCampaignsRequest]
  );

  // #region Update Campaign Type
  const {
    res: updateResponse,
    loading: updateLoading,
    setReq: setUpdateRequest
  } = useTextel<CrmCampaignType, CrmCampaignType>("Update Campaign Type", { method: "put" });
  useEffect(() => {
    if (!updateResponse) return;
    enqueueSnackbar("Campaign Type Updated", { variant: "success" });
    setSelectedCampaignType(undefined);
    setCrmCampaignTypes(oldCampaignTypes => {
      const index = oldCampaignTypes!.findIndex(c => c.id === updateResponse.payload.id);
      oldCampaignTypes![index] = {
        ...updateResponse.payload,
        TextelCampaignName: crmCampaignTypesRef.current?.find(ct => ct.id === updateResponse.payload.textelCampaignId)
          ?.name
      };
      return [...oldCampaignTypes!];
    });
  }, [updateResponse]);
  // #endregion

  // Actions
  const onSaveChanges = () =>
    setUpdateRequest(
      r => r && { ...r, url: `ZenotiCampaignTypes/${selectedCampaignType!.id}`, payload: selectedCampaignType }
    );

  return (
    <ToolWrapper title="Campaign Assignment" readme={toolReadme} sx={{ minWidth: "50rem" }}>
      <Grid container direction="column" alignItems="center" spacing={2} sx={{ height: "100%" }}>
        <Grid container item xs spacing={2} wrap="nowrap">
          <Grid item xs>
            <Grid container direction="column" spacing={2} wrap="nowrap" sx={{ height: "100%" }}>
              <Grid item>
                <AdminFormLabel label="Zenoti Campaign Types" readme={crmCampaignTypesReadme} />
              </Grid>
              <Grid item xs sx={{ overflow: "auto" }}>
                <SelectList
                  items={crmCampaignTypes}
                  value={selectedCampaignType || null}
                  disabled={loadingCampaignTypesRequest}
                  isItemEqualToValue={(i1, i2) => i1.id === i2.id}
                  onChange={newValue => setSelectedCampaignType(newValue || undefined)}
                  renderItem={ADGroupItem}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <AdminFormLabel
                  label={`Configuration ${selectedCampaignType ? ` for ${selectedCampaignType.name}` : ""}`}
                  readme={configurationLabelReadme}
                />
              </Grid>
              <Grid item>
                <Autocomplete
                  options={textelCampaigns}
                  renderInput={params => <TextField {...params} label="Textel Campaign" />}
                  getOptionLabel={campaign => campaign.name}
                  value={textelCampaigns.find(c => c.id === selectedCampaignType?.textelCampaignId) || null}
                  onChange={(_, campaign) =>
                    setSelectedCampaignType(type => ({ ...type!, textelCampaignId: campaign?.id || 0 }))
                  }
                  sx={{ minWidth: "25rem" }}
                  disabled={!selectedCampaignType}
                />
              </Grid>
              <Grid item>
                <ValidatedTextField
                  value={selectedCampaignType?.hoursDelay ?? ""}
                  label="Delay Broadcast"
                  onChange={hoursDelay =>
                    setSelectedCampaignType(
                      type => type && { ...type, hoursDelay: (hoursDelay && Number(hoursDelay)) || 0 }
                    )
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {selectedCampaignType?.hoursDelay === 1 ? "hour" : "hours"}
                      </InputAdornment>
                    )
                  }}
                  sx={{ maxWidth: "12.3rem" }}
                  rejectInput={value => !/^\d*$/.test(value)}
                  disabled={!selectedCampaignType}
                />
              </Grid>
              <Grid item container justifyContent="center">
                {updateLoading ? (
                  <CircularProgress />
                ) : (
                  <Button
                    disabled={
                      !selectedCampaignType ||
                      JSON.stringify(crmCampaignTypes?.find(ct => ct.id === selectedCampaignType.id)) ===
                        JSON.stringify(selectedCampaignType)
                    }
                    onClick={onSaveChanges}
                  >
                    Save Changes
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ToolWrapper>
  );
};

const ADGroupItem: React.FC<SelectListItemProps<EnhancedCrmCampaignType>> = ({ item: campaignType, ...rest }) => (
  <SelectListItem label={campaignType => campaignType.name} item={campaignType} {...rest}>
    {campaignType.TextelCampaignName && (
      <ListItemIcon>
        <Chip size="small" label={campaignType.TextelCampaignName} />
      </ListItemIcon>
    )}
    {Boolean(campaignType.hoursDelay) && (
      <ListItemIcon>
        <Chip size="small" label={`${campaignType.hoursDelay} Hour${campaignType.hoursDelay > 1 ? "s" : ""} Delay`} />
      </ListItemIcon>
    )}
  </SelectListItem>
);

export default CampaignAssignment;
