import axios, { AxiosInstance } from "axios";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useRef } from "react";
import { UseApiConfig, UseApiRequest, UseApiResponse, useApi } from "sonobello.utilities.react.axios";

import AppContext from "../components/AppContext";
// import RequestErrorSnackMessage from "../components/Helpers/RequestErrorSnackMessage";
import { TextelError } from "../models/TextelError";

const axiosInstance: AxiosInstance = axios.create({ baseURL: `${process.env.REACT_APP_API_URL}/api` });

export type UseTextelCustom<T extends Record<string, unknown> = Record<string, unknown>> = { name: string } & T;

export type UseTextelConfig = Omit<UseApiConfig, "axiosInstance">;
export interface UseTextelRequest<TRequestPayload = undefined, TCustom = undefined>
  extends Omit<UseApiRequest<TRequestPayload, TCustom>, "custom"> {
  custom?: TCustom;
}
export interface UseTextelProps<TRequestPayload = undefined, TCustom = undefined> {
  request?: UseTextelRequest<TRequestPayload, TCustom>;
  config?: UseTextelConfig;
}

/** An overloaded useApi hook which directly supplies the Axios instance to the Textel API backend and fires notifications on
 * failed or recovered requests.
 * @typeParam TResponsePayload - the type expected on the response payload, if any.
 * @typeParam TRequestPayload - the type expected on the request payload, if any.
 * @typeParam TCustom - the type expected on the custom props object, which will be merged with {@link UseTextelCustom}.
 * @typeParam TResponseError - the type expected on an error response payload, if any.
 * @param name - convenience param for the name of the hook, maps to {@link UseTextelCustom.name}.
 * @param request - see {@link UseApiProps.request} sans header auth params.
 * @param config - the initial {@link UseApiProps.config} sans {@link UseApiConfig.axiosInstance}.
 */
const useTextel = <
  TResponsePayload = undefined,
  TRequestPayload = undefined,
  TCustom extends Record<string, unknown> = Record<string, unknown>,
  TResponseError extends TextelError = TextelError
>(
  name: string,
  request?: UseTextelRequest<TRequestPayload, TCustom>,
  config?: UseTextelConfig
): UseApiResponse<TRequestPayload, UseTextelCustom<TCustom>, TResponseError, TResponsePayload> => {
  const { enqueueSnackbar } = useSnackbar();
  const { token, setToken } = useContext(AppContext);
  const hook = useApi<TResponsePayload, TRequestPayload, UseTextelCustom<TCustom>, TResponseError>({
    request: {
      ...request,
      custom: { name, ...request?.custom } as UseTextelCustom<TCustom>
    },
    config: { axiosInstance, token: token?.secret, isCancellable: false, ...config }
  });
  const responseRef = useRef(hook.res);
  const errorRef = useRef(hook.err);
  const tokenRef = useRef(token);

  // handle updating the authorization token
  useEffect(() => {
    let executeQuery = false;
    // if the token is defined, consider executing a query
    if (token) {
      // if token has been updated
      if (!tokenRef.current) {
        if (
          // execute if the previous request failed due to a bad token, or
          errorRef.current?.error?.response?.status === 401 ||
          // execute if the request has not fired yet
          (!errorRef.current && !responseRef.current)
        )
          executeQuery = true;
      }
    }
    hook.setConf((c: UseApiConfig) => ({ ...c, token: token?.secret }));
    if (executeQuery) hook.setReq(r => r && { ...r });
    tokenRef.current = token;
  }, [token]);

  responseRef.current = hook.res;

  // handle receiving or resolving error responses
  useEffect(() => {
    // if an error occurred
    if (hook.err) {
      if (hook.err?.error.response?.status === 401) setToken(undefined);
      else enqueueSnackbar(`${hook.err.request.custom.name} Failed`, { variant: "error" });
    }
    // else if resolving a previous failure
    else if (errorRef.current)
      enqueueSnackbar(`${errorRef.current.error.request.custom.name} Resolved`, { variant: "success" });
    errorRef.current = hook.err;
  }, [hook.err]);

  return hook;
};

export default useTextel;
