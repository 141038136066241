import { Button, Chip, CircularProgress, FormControlLabel, FormGroup, Grid, ListItemIcon, Switch } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { AdminFormLabel, SelectList, SelectListItem, SelectListItemProps } from "sonobello.utilities.react.mui";

import { CrmDisposition } from "../../models/CrmDisposition";
import useTextel from "../../utils/UseTextel";
import { AdminTool } from "../Main";
import ToolWrapper from "../ToolWrapper";

const toolReadme = `
  The Zenoti Dispositions tool allows you to review and modify the configuration for which dispositions in Zenoti are flagged as \`Do Not Call\` or as \`NonCandidate\`.
`;
const crmDispositionReadme = `Select a Zenoti Disposition to configure.`;
const configurationLabelReadme = `Modify the \`Do Not Call\` or \`NonCandidate\` status of the disposition.`;

const ZenotiDispositions: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { enqueueSnackbar } = useSnackbar();
  const [dispositions, setDispositions] = useState<CrmDisposition[]>([]);
  const [selectedDisposition, setSelectedDisposition] = useState<CrmDisposition>();

  // Get CrmCampaignTypes
  const { res: getResponse, loading: getLoading } = useTextel<CrmDisposition[]>("Get Crm Dispositions", {
    url: "ZenotiDispositions"
  });
  useEffect(() => setDispositions(getResponse?.payload || []), [getResponse]);

  // #region Update Disposition
  const {
    res: updateResponse,
    loading: updateLoading,
    setReq: setUpdateRequest
  } = useTextel<CrmDisposition, CrmDisposition>("Update Zenoti Disposition", { method: "put" });
  useEffect(() => {
    if (!updateResponse) return;
    enqueueSnackbar("Disposition Updated", { variant: "success" });
    setSelectedDisposition(undefined);
    setDispositions(oldDispositions => {
      const index = oldDispositions.findIndex(d => d.id === updateResponse.payload.id);
      oldDispositions[index] = updateResponse.payload;
      return [...oldDispositions];
    });
  }, [updateResponse]);
  // #endregion

  //#region Actions
  const onSaveChanges = () =>
    setUpdateRequest(
      r => r && { ...r, url: `ZenotiDispositions/${selectedDisposition!.id}`, payload: selectedDisposition }
    );
  //#endregion

  // show spinner while loading
  useEffect(() => setIsContentLoading(getLoading), [getLoading]);

  return (
    <ToolWrapper title="Zenoti Dispositions" readme={toolReadme} sx={{ minWidth: "50rem" }}>
      <Grid container spacing={2} sx={{ m: 0, height: "100%" }}>
        <Grid item xs>
          <Grid container direction="column" spacing={2} sx={{ height: "100%" }} wrap="nowrap">
            <Grid item>
              <AdminFormLabel label="Zenoti Dispositions" readme={crmDispositionReadme} />
            </Grid>
            <Grid item xs sx={{ overflow: "auto" }}>
              <SelectList
                items={dispositions}
                value={selectedDisposition || null}
                disabled={getLoading}
                isItemEqualToValue={(i1, i2) => i1.id === i2.id}
                onChange={newValue => setSelectedDisposition(newValue || undefined)}
                renderItem={CrmDispositionItem}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={2} sx={{ minWidth: "23rem" }}>
            <Grid item>
              <AdminFormLabel
                label={`${selectedDisposition?.name || ""} Configuration`}
                readme={configurationLabelReadme}
              />
            </Grid>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  control={<Switch />}
                  disabled={!selectedDisposition}
                  checked={selectedDisposition?.doNotCall || false}
                  onChange={(_, checked) => setSelectedDisposition(d => ({ ...d!, doNotCall: checked }))}
                  label="Do Not Call"
                />
                <FormControlLabel
                  control={<Switch />}
                  disabled={!selectedDisposition}
                  checked={selectedDisposition?.nonCandidate || false}
                  onChange={(_, checked) => setSelectedDisposition(d => ({ ...d!, nonCandidate: checked }))}
                  label="Non-Candidate"
                />
              </FormGroup>
            </Grid>
            <Grid item container justifyContent="center">
              {updateLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  disabled={
                    !selectedDisposition ||
                    JSON.stringify(dispositions.find(d => d.id === selectedDisposition.id)) ===
                      JSON.stringify(selectedDisposition)
                  }
                  onClick={onSaveChanges}
                >
                  Save Changes
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ToolWrapper>
  );
};

const CrmDispositionItem: React.FC<SelectListItemProps<CrmDisposition>> = ({ item: disposition, ...rest }) => (
  <SelectListItem {...rest} label={disposition => disposition.name} item={disposition}>
    {disposition.doNotCall && (
      <ListItemIcon>
        <Chip size="small" label="Do Not Call" />
      </ListItemIcon>
    )}
    {disposition.nonCandidate && (
      <ListItemIcon>
        <Chip size="small" label="Non-Candidate" />
      </ListItemIcon>
    )}
  </SelectListItem>
);

export default ZenotiDispositions;
